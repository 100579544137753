import allRoutes from "router/routes";

import Dashboard from "assets/Dashboard.png";
import CaseStudy from "assets/CaseStudy.png";
// import Reports from "assets/Reports.png";
import Users from "assets/Users.png";
import NonCompliance from "assets/NonCompliance.png";
import Roles from "assets/Roles.png";
import Profile from "assets/Profile.png";
import MasterSettings from "assets/MasterSettings.png";
import ChangePassword from "assets/ChangePassword.png";
import PreSales from "assets/PreSales.png";
import Logout from "assets/Logout.png";
import TrainingBook from "assets/TrainingBook.png";
import InsideSales from "assets/InsideSales.png";
import MockInterview from "assets/MockInterview.png";
import Assignment from "assets/Assignment.png";
import Testimonial from "assets/Testimonial.png";
import ISTools from "assets/ISTools.png";
import Referrals from "assets/Referrals.png";
import Refer from "assets/Refer.png";
import User from "assets/User.png";
import CampusPlacement from "assets/CampusPlacement.png"
import Technical_Interview from "assets/Technical_Interview.png";
import T from "T";

import {
  canViewDashboard,
  // canViewReport,
  canViewUser,
  canViewNC,
  canViewRole,
  canViewMasterSettings,
  canViewPreSales,
  canViewTraining,
  canViewTrainee,
  canViewInsideSales,
  canViewISTools,
  canViewCaseStudy,
  canViewTestimonial,
  canViewTechAssignment,
  canAddTechAssignment,
  canViewMockAssessment,
  canViewReferral,
  canViewRecommendations,
  canViewRecommendationsTab,
  canViewProfile,
  canViewTechnicalInterview,
  canAddTechnicalInterview,
  canViewCampusPlacement,
  canAddCampusPlacement,
  canEditCampusPlacement,
  canDeleteCampusPlacement,
} from "utils/permissions";
import { get } from "lodash";
import { store } from "providers/store";

const {
  DASHBOARD,
  CASESTUDY,
  TESTIMONIAL,
  // REPORTS,
  MEMBERS,
  NON_COMPLIANCE,
  ROLE,
  MASTER_SETTINGS,
  PRE_SALES,
  TRAINING,
  INSIDE_SALES,
  IS_TOOLS,
  MOCK_INTERVIEW,
  MOCK_ASSESSMENT,
  TECHNICAL_ASSIGNMENT,
  REFER,
  EMPLOYEE_PROFILE,
  PROFILE,
  CHANGE_PWD,
  LOGOUT,
  REFERRALS,
  RECOMMENDATIONS,
  TECHNICAL_INTERVIEW,
  CAMPUS_PLACEMENT,
} = T;

const { app } = allRoutes;
const {
  dashboard,
  caseStudy,
  csHistory,
  testimonial,
  // reports,
  members,
  addMembers,
  addConsultant,
  editMembers,
  viewMembers,
  feedback,
  feedbackForm,
  nonCompliance,
  roles,
  addRoles,
  editRoles,
  masterSettings,
  preSales,
  allPitchedMembers,
  presalesReportCard,
  editInterviewTracker,
  scheduleInterview,
  scheduleMockInterview,
  codeAssessment,
  pitchedMembers,
  createNewProject,
  editPreSalesProject,
  recommendedMembers,
  shortlistedMembers,
  training,
  groups,
  addCourse,
  editCourse,
  traineeCourse,
  insideSales,
  mockInterview,
  mockAssessment,
  mockAssessmentResult,
  mockInterviewReponses,
  profile,
  changePwd,
  test,
  interviewChecklist,
  viewClient,
  memberAssignments,
  addAssignment,
  assignedTopics,
  isTools,
  addISLicense,
  editISLicense,
  swotAnalysis,
  referrals,
  referral,
  referralRecommendationForm,
  refer,
  employeeProfile,
  recommendationDetails,
  technicalInterview,
  technicalInterviewUserPage,
  campusPlacement,
} = app;

const referralAdded = () => {
  const state = store.getState();
  return get(state, "ReferSlice.referralAdded", null);
};

const assignmentChildList = [memberAssignments, addAssignment, assignedTopics];
export const navbarItems = () => [
  {
    title: EMPLOYEE_PROFILE,
    route: employeeProfile,
    icon: User, //todo icon
    permission: canViewProfile(),
    childList: [employeeProfile],
  },

  {
    title: DASHBOARD,
    route: dashboard,
    icon: Dashboard,
    permission: canViewDashboard(),
    childList: [dashboard],
  },
  // {
  //   title: REPORTS,
  //   route: reports,
  //   icon: Reports,
  //   permission: canViewReport(),
  //   childList: [reports],
  // },
  {
    title: MEMBERS,
    route: members,
    icon: Users,
    permission: canViewUser(),
    childList: canAddTechAssignment()
      ? [
          members,
          addMembers,
          addConsultant,
          editMembers,
          viewMembers,
          feedback,
          feedbackForm,
          memberAssignments,
          swotAnalysis,

          addAssignment,
          assignedTopics,
        ]
      : [members, addMembers, addConsultant, editMembers, viewMembers, feedback, feedbackForm, swotAnalysis],
  },

  {
    title: NON_COMPLIANCE,
    route: nonCompliance,
    icon: NonCompliance,
    permission: canViewNC(),
    childList: [nonCompliance],
  },
  {
    title: `${ROLE}s`,
    route: roles,
    icon: Roles,
    permission: canViewRole(),
    childList: [roles, addRoles, editRoles],
  },
  {
    title: MASTER_SETTINGS,
    route: masterSettings,
    icon: MasterSettings,
    permission: canViewMasterSettings(),
    childList: [masterSettings],
  },
  {
    title: PRE_SALES,
    route: preSales,
    icon: PreSales,
    permission: canViewPreSales(),
    childList: [
      preSales,
      createNewProject,
      editPreSalesProject,
      recommendedMembers,
      shortlistedMembers,
      allPitchedMembers,
      pitchedMembers,
      editInterviewTracker,
      scheduleInterview,
      presalesReportCard,
      scheduleMockInterview,
      codeAssessment,
      interviewChecklist,
      viewClient,
    ],
  },
  {
    title: TRAINING,
    route: training,
    icon: TrainingBook,
    // permission: canViewTrainee() || canViewTraining(),
    permission: true,
    childList: [training, groups, addCourse, editCourse, traineeCourse],
  },
  {
    title: INSIDE_SALES,
    route: insideSales,
    icon: InsideSales,
    permission: canViewInsideSales(),
    childList: [insideSales],
  },
  {
    title: IS_TOOLS,
    route: isTools,
    icon: ISTools,
    permission: canViewISTools(),
    childList: [isTools, addISLicense, editISLicense],
  },
  {
    title: MOCK_INTERVIEW,
    route: mockInterview,
    icon: MockInterview,
    permission: true,
    childList: [mockInterview, mockInterviewReponses],
  },
  {
    title: MOCK_ASSESSMENT,
    route: mockAssessment,
    icon: MockInterview,
    permission: canViewMockAssessment(),
    childList: [mockAssessment, mockAssessmentResult],
  },
  {
    title: TECHNICAL_ASSIGNMENT,
    route: memberAssignments,
    icon: Assignment,
    permission: canViewTechAssignment() && !canAddTechAssignment(),
    childList: [memberAssignments, addAssignment, assignedTopics],
  },
  {
    title: RECOMMENDATIONS,
    route: referrals,
    icon: Referrals,
    permission: canViewRecommendations() || canViewRecommendationsTab(),
    childList: [referrals, referralRecommendationForm, recommendationDetails],
  },
  {
    title: REFER,
    route: refer,
    icon: Refer,
    permission: referralAdded() && canViewReferral(),
    childList: [refer],
  },
  {
    title: TECHNICAL_INTERVIEW,
    route: technicalInterviewUserPage,
    icon: Technical_Interview,
    permission: canViewTechnicalInterview() && !canAddTechnicalInterview(),
    childList: [technicalInterviewUserPage],
  },

  {
    title: CASESTUDY,
    route: caseStudy,
    icon: CaseStudy,
    permission: canViewCaseStudy(),
    childList: [caseStudy, csHistory],
  },
  {
    title: CAMPUS_PLACEMENT,
    route: campusPlacement,
    icon: CampusPlacement,
    permission: canViewCampusPlacement(),
    childList: [campusPlacement],
  },
  // {
  //   title: TESTIMONIAL,
  //   route: testimonial,
  //   icon: Testimonial,
  //   permission: canViewTestimonial(),
  //   childList: [testimonial],
  // },
];

export const dropDownItems = () => [
  {
    title: "Test",
    route: test,
    permission: true,
  },
];

export const profileDropDownItems = () => [
  {
    title: PROFILE,
    route: profile,
    icon: Profile,
    permission: true,
    childList: [profile],
  },
  {
    title: CHANGE_PWD,
    route: changePwd,
    icon: ChangePassword,
    permission: true,
    childList: [changePwd],
  },
  {
    title: LOGOUT,
    icon: Logout,
    permission: true,
  },
];

export const mobileDropDownItems = () => [...navbarItems(), ...dropDownItems(), ...profileDropDownItems()];
